import React from "react";
import QuejasImg from "../../imagenes/quejas.png";
import quejasyreclamosApi from "../../services/quejasyreclamosApi";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

class QuejasYReclamos extends React.Component {
  state = {
    form: {
      nombres: "",
      identificacion: "",
      email: "",
      idtipoformulario: "",
      mensaje: "",
      celular: "",
      fechahora: "",
      idprovincia: "",
      idcuidad: "",
      urlarchivo: "",
      estadoformulario: 1,
      idagencia: "",
      file: "",
    },
    tiposFormularios: [],
    estadosFormularios: [],
    agencias: [],
    provincias: [],
    ciudades: [],
    archivoExtension: false,
    loading: false,
  };

  getProvincias = async () => {
    try {
      const data = await quejasyreclamosApi.api.provincias();
      this.setState({ provincias: data.response });
    } catch (error) {
      console.log(error);
    }
  };

  getCantones = async (secuencialDivisionPadre) => {
    try {
      const data = await quejasyreclamosApi.api.ciudades(
        secuencialDivisionPadre
      );
      this.setState({ ciudades: data.response });
    } catch (error) {
      console.log(error);
    }
  };

  getTipoFormulario = async () => {
    try {
      const data = await quejasyreclamosApi.api.tipoformulario();
      this.setState({ tiposFormularios: data.response });
    } catch (error) {
      console.log(error);
    }
  };

  getAgencias = async () => {
    try {
      const data = await quejasyreclamosApi.api.agencias();
      this.setState({ agencias: data.response });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (e) => {
    if (e.target.name === "idprovincia") {
      this.getCantones(e.target.value);
    }
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  subirArchivo = (file, e) => {
    if (file) {
      var extension = file.name.split(".").pop();
      extension = extension.toLowerCase();
      if (
        extension === "jpg" ||
        extension === "jpeg" ||
        extension === "png" ||
        extension === "gif" ||
        extension === "webp" ||
        extension === "svg" ||
        extension === "pdf" ||
        extension === "docx"
      ) {
        this.setState({ archivoExtension: true });
        this.state.form.urlarchivo = this.uuidv4() + "." + extension;
        if (file) {
          var reader = new FileReader();
          reader.onload = this._handleReaderLoaded1.bind(this);
          reader.readAsBinaryString(file);
        }
      } else {
        this.setState({ archivoExtension: false });
      }
    }
  };

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  _handleReaderLoaded1(readerEvent) {
    var binaryString = readerEvent.target.result;
    this.state.form.file = btoa(binaryString);
    //this.setState({ formjustificacion: { binarioArchivo: btoa(binaryString) } })
  }

  enviarQuejas = async (e) => {
    e.preventDefault();
    try {
      this.setState({ loading: true });
      const data = await quejasyreclamosApi.api.agregarFormulario({
        nombres: this.state.form.nombres,
        identificacion: this.state.form.identificacion,
        email: this.state.form.email,
        idtipoformulario: +this.state.form.idtipoformulario,
        mensaje: this.state.form.mensaje,
        celular: this.state.form.celular,
        fechahora: this.state.form.fechahora,
        idprovincia: +this.state.form.idprovincia,
        idcuidad: +this.state.form.idcuidad,
        urlarchivo: this.state.form.urlarchivo,
        estadoformulario: this.state.form.estadoformulario,
        idagencia: +this.state.form.idagencia,
        file: this.state.form.file,
      });
      this.limpiarForm();
      toast.success("Formulario realizado correctamente!");
      this.setState({ loading: false });
    } catch (error) {
      toast.error("error " + error);
    }
  };

  cargando = () => {
    if (this.state.loading == true) {
      return (
        <div className="d-flex align-items-center">
          <strong>Cargando...</strong>
          <div
            className="spinner-border ml-auto"
            role="status"
            aria-hidden="true"
          ></div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  limpiarForm = () => {
    this.setState({
      form: {
        nombres: "",
        identificacion: "",
        email: "",
        idtipoformulario: "",
        mensaje: "",
        celular: "",
        fechahora: "",
        idprovincia: "",
        idcuidad: "",
        urlarchivo: "",
        estadoformulario: 1,
        idagencia: "",
        file: "",
      },
    });
  };

  componentDidMount() {
    console.log("ID:", this.props.match.params.id);
    this.getTipoFormulario();
    this.getAgencias();
    this.getProvincias();
  }

  goToTop = () => {
    window.scrollTo({
      top: 650,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1>
                  Gracias a tus comentarios, tendremos la oportunidad de mejorar
                  y brindarte un mejor servicio
                </h1>
                <div className="d-flex justify-content-center justify-content-lg-start mt-3">
                  <a
                    onClick={this.goToTop}
                    className="btn-get-started scrollto"
                  >
                    Llenar formulario
                  </a>
                  <Link
                    to="/seguimiento-quejas-reclamos-sugerencias"
                    className="glightbox btn-watch-video"
                  >
                    <i className="bi bi-arrow-up-right-square"></i>
                    <span>Ver proceso</span>
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img
                  src={QuejasImg}
                  className="img-fluid animated rounded "
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <main id="main">
          <section id="services" className="services section-bg">
            <br />
            <div className="container" data-aos="fade-up">
              <div className="alert alert-primary" role="alert">
                <h5 className="texto-justificado mb-0">
                  Formulario de quejas, sugerencias o reclamos
                </h5>
              </div>
              <br />
              <h5>
                <b>Nota:</b> Luego de enviar el formulario, se notifica al
                usuario por correo electrónico la etapa del proceso.
              </h5>
              <br />
              <br />
              <form onSubmit={this.enviarQuejas}>
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label className="ps-1">
                        Nombres completos<b className="text-danger">*</b>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        onChange={this.handleChange}
                        name="nombres"
                        value={this.state.form.nombres}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label className="ps-1">
                        Identificación<b className="text-danger">*</b>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        pattern="[0-9]+"
                        onChange={this.handleChange}
                        name="identificacion"
                        value={this.state.form.identificacion}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label className="ps-1">
                        Correo electrónico<b className="text-danger">*</b>
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        onChange={this.handleChange}
                        name="email"
                        value={this.state.form.email}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label className="ps-1">
                        Celular<b className="text-danger">*</b>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        pattern="[0-9]+"
                        onChange={this.handleChange}
                        name="celular"
                        value={this.state.form.celular}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label className="ps-1">
                        Provincia<b className="text-danger">*</b>
                      </label>
                      <select
                        className="form-control"
                        onChange={this.handleChange}
                        name="idprovincia"
                        value={this.state.form.idprovincia}
                        required
                      >
                        <option value={""}>...</option>
                        {this.state.provincias.map((data, index) => {
                          return (
                            <option value={data.secuencial} key={index + 1}>
                              {data.nombre}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label className="ps-1">
                        Ciudad<b className="text-danger">*</b>
                      </label>
                      <select
                        className="form-control"
                        onChange={this.handleChange}
                        name="idcuidad"
                        value={this.state.form.idcuidad}
                        required
                      >
                        <option value={""}>...</option>
                        {this.state.ciudades.map((data, index) => {
                          return (
                            <option value={data.secuencial} key={index + 1}>
                              {data.nombre}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label className="ps-1">
                        Tipo de formulario<b className="text-danger">*</b>
                      </label>
                      <select
                        className="form-control"
                        onChange={this.handleChange}
                        name="idtipoformulario"
                        value={this.state.form.idtipoformulario}
                        required
                      >
                        <option value={""}>...</option>
                        {this.state.tiposFormularios.map((data, index) => {
                          return (
                            <option
                              value={data.idtipoformulario}
                              key={index + 1}
                            >
                              {data.tipo}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label className="ps-1">
                        Agencia<b className="text-danger">*</b>
                      </label>
                      <select
                        className="form-control"
                        onChange={this.handleChange}
                        name="idagencia"
                        value={this.state.form.idagencia}
                        required
                      >
                        <option value={""}>...</option>
                        {this.state.agencias.map((data, index) => {
                          return (
                            <option value={data.idagencia} key={index + 1}>
                              {data.agencia}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="form-group">
                      <label className="ps-1">
                        Descripción<b className="text-danger">*</b>
                      </label>
                      <textarea
                        className="form-control"
                        rows={5}
                        maxLength={3600}
                        onChange={this.handleChange}
                        name="mensaje"
                        value={this.state.form.mensaje}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <label className="ps-1">
                      Adjuntar archivo formato (.pdf, .docx, .png, .jpg, .jpeg,
                      .gif, .webp, .svg)
                    </label>
                    <br />
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => this.subirArchivo(e.target.files[0], e)}
                    />
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-lg-8"></div>
                    <div className="col-lg-4">
                      <div className="text-center">{this.cargando()}</div>
                    </div>
                  </div>

                  <div className="col-lg-12 mb-4 text-end">
                    <button className="mt-3 btnn" type="submit">
                      Enviar Formulario
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <br />
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default QuejasYReclamos;
