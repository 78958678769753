import Global from "./urlGlobal";
const BASE_URL = Global.UrlGlobal.urlApiPremios;

async function callApi(endpoint, options) {
  const url = BASE_URL + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
}

const apiPremios = {
  listAwards: {
    create() {
      return callApi(`api/ganapuntos/listAwards`, {
        method: "GET",
      });
    },
  },
  getSecuencialClient: {
    create(identificacion) {
      return callApi(`api/ganapuntos/getSecuencialClient`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({identificacion}),
      });
    },
  },
  listScoreTotal: {
    create(data) {
      return callApi(`api/ganapuntos/listScoreTotal`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    },
  },
  urlBaseImage: BASE_URL,
};
export default apiPremios;
