// const URL_BASE_JOB_OFFERS_API = "http://localhost:3000";
import Global from "./urlGlobal";
const BASE_URL = Global.UrlGlobal.urlNoticias;

export class JobOfferApi {
  static async getAllJobOffers() {
    const response = await fetch(`${BASE_URL}job-offers?status=1`);

    if (!response.ok) {
      throw new Error("Error al recuperar los datos");
    }

    const data = await response.json();
    return data;
  }

  static async getJobOfferById(id) {
    const response = await fetch(`${BASE_URL}job-offers/${id}`);

    if (!response.ok) {
      throw new Error("Error al recuperar los datos");
    }

    const data = await response.json();
    return data;
  }

}
