import React from "react";
import logoVertical from "../../imagenes/logoblancas.png";
import { PDFViewer } from 'react-view-pdf';

class Indicadoresgenero extends React.Component {


    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 d-flex flex-column  pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <div className="text-center">
                                    <h1>Indicadores de Género</h1>
                                    <h2>Diciembre 31 del 2022</h2>
                                </div>

                                <div className="text-center pt-0">
                                    <img src={logoVertical} className="logovertical-gerencia" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <br />  <br />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="alert alert-primary" role="alert">
                                    <h5 className="texto-justificado mb-0">
                                        Indicador Género Depósitos
                                    </h5>
                                </div>
                                <PDFViewer url="https://futurolamanense.fin.ec/docs/genero/IndicadorGeneroDepositos_Report31122022.pdf" />
                            </div>
                            <div className="col-lg-6">
                                <div className="alert alert-primary" role="alert">
                                    <h5 className="texto-justificado mb-0">
                                        Indicador Género Acceso Créditos
                                    </h5>
                                </div>
                                <PDFViewer url="https://futurolamanense.fin.ec/docs/genero/IndicadorGeneroAccesoCreditos_Report31122022.pdf" />
                            </div>
                        </div>
                        <br />  <br />
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="alert alert-primary" role="alert">
                                    <h5 className="texto-justificado mb-0">
                                        Indicador Género Monto Promedio
                                    </h5>
                                </div>
                                <PDFViewer url="https://futurolamanense.fin.ec/docs/genero/IndicadorGeneroMontoPromedio_Report31122022.pdf" />
                            </div>
                            <div className="col-lg-6">
                                <div className="alert alert-primary" role="alert">
                                    <h5 className="texto-justificado mb-0">
                                        Indicador Género Mora
                                    </h5>
                                </div>
                                <PDFViewer url="https://futurolamanense.fin.ec/docs/genero/IndicadorGeneroMora_Report31122022.pdf" />
                            </div>
                        </div>
                        <br />  <br />
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="alert alert-primary" role="alert">
                                    <h5 className="texto-justificado mb-0">
                                        Indicador Género Representación
                                    </h5>
                                </div>
                                <PDFViewer url="https://futurolamanense.fin.ec/docs/genero/IndicadorGeneroRepresentacion_Report31122022.pdf" />
                            </div>
                        </div>
                    </div>
                    <br />  <br />
                </main>
            </React.Fragment>
        )
    }
}//

export default Indicadoresgenero;