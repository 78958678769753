import React from "react";
import { Link } from "react-router-dom";
import logofondooscuro from "../../imagenes/logosaniversario/logogris.png"; //import logofondooscuro from "../../imagenes/logohorizont.svg"
import logofondoclaro from "../../imagenes/logosaniversario/logoblanco.png"; //import logofondoclaro from "../../imagenes/logosnormales/logoblanco.png"

class Navbar extends React.Component {
  componentDidMount() {
    let preloader = document.querySelector("#preloader");
    if (preloader) {
      window.addEventListener("load", () => {
        preloader.remove();
      });
    }

    let selectHeader = document.querySelector("#header");
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add("header-scrolled");
        } else {
          selectHeader.classList.remove("header-scrolled");
        }
      };
      window.addEventListener("load", headerScrolled);
      const onscroll = (el, listener) => {
        el.addEventListener("scroll", listener);
      };
      onscroll(document, headerScrolled);
    }

    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };

    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    const scrollto = (el) => {
      let header = select("#header");
      let offset = header.offsetHeight;

      let elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    };

    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };

    let backtotop = select(".back-to-top");
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add("active");
        } else {
          backtotop.classList.remove("active");
        }
      };
      window.addEventListener("load", toggleBacktotop);
      onscroll(document, toggleBacktotop);
    }

    on("click", ".mobile-nav-toggle", function (e) {
      select("#navbar").classList.toggle("navbar-mobile");
      this.classList.toggle("bi-list");
      this.classList.toggle("bi-x");
    });

    on(
      "click",
      ".navbar .dropdown > a",
      function (e) {
        if (select("#navbar").classList.contains("navbar-mobile")) {
          e.preventDefault();
          this.nextElementSibling.classList.toggle("dropdown-active");
        }
      },
      true
    );

    on(
      "click",
      ".scrollto",
      function (e) {
        if (select(this.hash)) {
          e.preventDefault();

          let navbar = select("#navbar");
          if (navbar.classList.contains("navbar-mobile")) {
            navbar.classList.remove("navbar-mobile");
            let navbarToggle = select(".mobile-nav-toggle");
            navbarToggle.classList.toggle("bi-list");
            navbarToggle.classList.toggle("bi-x");
          }
          scrollto(this.hash);
        }
      },
      true
    );
  }

  render() {
    return (
      <React.Fragment>
        <header id="header" className="fixed-top ">
          <div className="container d-flex align-items-center">
            <h1 className="logo me-auto">
              <Link to="/" className="text-dark">
                <img src={logofondooscuro} className="logoflm-oscuro" />
                <img src={logofondoclaro} className="logoflm-claro" />
              </Link>
            </h1>

            <nav id="navbar" className="navbar">
              <ul>
                <li className="dropdown">
                  <a className="cursorp menu-link-su" href="#">
                    <span>Nosotros</span> <i className="bi bi-chevron-down"></i>
                  </a>
                  <ul>
                    <li>
                      <Link to={"/index"} className="link-menu">
                        Inicio
                      </Link>
                    </li>
                    <li>
                      <Link to={"/noticias"} className="link-menu">
                        Noticias
                      </Link>
                    </li>
                    <li>
                      <Link to="/quienes-somos" className="link-menu">
                        ¿Quienes Somos?
                      </Link>
                    </li>
                    <li>
                      <Link to="/resena-historica" className="link-menu">
                        Reseña Histórica
                      </Link>
                    </li>
                    <li>
                      <Link to={"/saras"} className="link-menu">
                        SARAS
                      </Link>
                    </li>
                    <li className="dropdown">
                      <a className="link-menu cursorp">
                        <span>Transparencia</span>{" "}
                        <i className="bi bi-chevron-right"></i>
                      </a>
                      <ul>
                        <li>
                          <Link to="/cosede" className="link-menu">
                            Cosede
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/informacion-financiera"
                            className="link-menu"
                          >
                            Información Financiera
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/gerencia" className="link-menu">
                        Gerencia
                      </Link>
                    </li>
                    <li>
                      <Link to="/agencias" className="link-menu">
                        Agencias
                      </Link>
                    </li>
                    <li>
                      <Link to="/gobierno-corporativo" className="link-menu">
                        Gobierno Corporativo
                      </Link>
                    </li>
                    <li>
                      <Link to={"/job-offersList"} className="link-menu">
                        Trabaja con nosotros
                      </Link>
                    </li>
                    <li>
                      <Link to="/indicadores-genero" className="link-menu">
                        Indicadores de Género
                      </Link>
                    </li>
                    {/*
                                            <li>
                                            <a className="link-menu" href="https://futurolamanense.fin.ec/Normativa.pdf" target="_blank">
                                                Normativa
                                            </a>
                                        </li>
                                            */}
                    <li>
                      <Link to="/codigo-etica" className="link-menu">
                        Código de Ética
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a className="menu-link-su cursorp">
                    <span>Productos</span>{" "}
                    <i className="bi bi-chevron-down"></i>
                  </a>
                  <ul>
                    <li className="dropdown">
                      <a className="link-menu cursorp">
                        <span>Ahorros</span>{" "}
                        <i className="bi bi-chevron-right"></i>
                      </a>
                      <ul>
                        <li>
                          <Link to="/cuenta-ahorros" className="link-menu">
                            Cuenta de Ahorros
                          </Link>
                        </li>
                        {/* <li>
                              <Link
                                to="/ahorro-inversion"
                                className="link-menu"
                              >
                                Ahorro de Inversión
                              </Link>
                            </li> */}
                        <li>
                          <Link to="/ahorro-junior" className="link-menu">
                            Ahorro Junior
                          </Link>
                        </li>
                        <li>
                          <Link to="/ahorro-programado" className="link-menu">
                            Ahorro Programado
                          </Link>
                        </li>
                        <li>
                          <Link to="/ahorro-inclusion" className="link-menu">
                            Ahorro Inclusión
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/ahorro-inclusion-junior"
                            className="link-menu"
                          >
                            Ahorro Inclusión Junior
                          </Link>
                        </li>
                        <li>
                          <Link to="/ahorro-dual" className="link-menu">
                            Ahorro Dual
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a className="link-menu cursorp">
                        <span>Créditos</span>{" "}
                        <i className="bi bi-chevron-right"></i>
                      </a>
                      <ul>
                        <li>
                          <Link to="/credito-consumo" className="link-menu">
                            Crédito de Consumo
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/credito-inmobiliario"
                            className="link-menu"
                          >
                            Crédito Inmobiliario
                          </Link>
                        </li>
                        <li className="dropdown">
                          <a className="link-menu cursorp">
                            <span>Microcréditos</span>{" "}
                            <i className="bi bi-chevron-right"></i>
                          </a>
                          <ul>
                            <li>
                              <Link to="/microcredito" className="link-menu">
                                Microcrédito
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/credito-futuconfianza"
                                className="link-menu"
                              >
                                Crédito Futuconfianza
                              </Link>
                            </li>
                            <li>
                              <Link to="/credito-premium" className="link-menu">
                                Crédito Premium
                              </Link>
                            </li>
                            <li>
                              <Link to="/eco-futuro" className="link-menu">
                                Eco Futuro
                              </Link>
                            </li>
                          </ul>
                        </li>
                        {/*
                                                    <li>
                                                    <Link to="/futu-navideno" className="link-menu" >
                                                        Futu Navideño
                                                    </Link>
                                                </li>
                                                    */}
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a className="link-menu cursorp">
                        <span>Inversiones</span>{" "}
                        <i className="bi bi-chevron-right"></i>
                      </a>
                      <ul>
                        <li>
                          <Link to="/deposito-plazo-fijo" className="link-menu">
                            Depósito a Plazo Fijo
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a className="link-menu cursorp">
                        <span>Beneficios</span>{" "}
                        <i className="bi bi-chevron-right"></i>
                      </a>
                      <ul>
                        <li>
                          <Link to="/peluqueria" className="link-menu">
                            Peluquería
                          </Link>
                        </li>
                        <li>
                          <Link to="/odontologia" className="link-menu">
                            Odontología
                          </Link>
                        </li>
                        <li>
                          <Link to="/medicina-general" className="link-menu">
                            Medicina General
                          </Link>
                        </li>
                        <li>
                          <Link to="/fondo-mortuorio" className="link-menu">
                            Fondo Mortuorio
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/educacion-financiera" className="menu-link-su">
                    Educación Financiera
                  </Link>
                </li>
                <li className="dropdown">
                  <a className="cursorp menu-link-su">
                    <span>En Línea</span> <i className="bi bi-chevron-down"></i>
                  </a>
                  <ul>
                    <li>
                      <a
                        className="link-menu"
                        href="https://bdfl.futurolamanense.fin.ec/login"
                        target="_blank"
                      >
                        Billetera Digital
                      </a>
                    </li>
                    <li>
                      <a
                        className="link-menu"
                        href="https://solicitudcredito.futurolamanense.fin.ec/"
                        target="_blank"
                      >
                        Crédito en línea
                      </a>
                    </li>
                    <li>
                      <Link to="/recomienda-y-gana" className="link-menu">
                        Recomienda y Gana
                      </Link>
                    </li>
                    <li>
                      <Link to="/futuro-premia" className="link-menu">
                        Futuro te premia
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://pagos.facilito.com.ec/aplicacion/futurolamanense"
                        target="_blank"
                        className="link-menu"
                      >
                        Portal de pagos
                      </a>
                    </li>
                    <li className="dropdown">
                      <a className="cursorp link-menu">
                        <span>Centro de ayuda</span>{" "}
                        <i className="bi bi-chevron-right"></i>
                      </a>
                      <ul>
                        <li>
                          <Link to="/horarios" className="link-menu">
                            Horarios de Atención
                          </Link>
                        </li>
                        <li>
                          <Link to="/contactos" className="link-menu">
                            Contáctanos
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/quejas-reclamos-sugerencias/1"
                            className="link-menu"
                          >
                            Quejas, sugerencias y reclamos
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    className="getstarted scrollto"
                    href="https://bdfl.futurolamanense.fin.ec/login"
                  >
                    Billetera Digital
                  </a>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle"></i>
            </nav>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Navbar;
