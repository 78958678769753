import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Importa tus imágenes aquí
import saraDesktop from "./../../imagenes/slider/saras-desktop.jpg";
import saraMobile from "./../../imagenes/slider/saras-mobile.jpg";
import creditoFutuconfianzaDesktop from "./../../imagenes/slider/credito-futuconfianza-desktop.jpg";
import creditoFutuconfianzaMobile from "./../../imagenes/slider/credito-futuconfianza-mobile.jpg";
import ahorroInclusionDesktop from "./../../imagenes/slider/ahorro-inclusion-desktop.jpg";
import ahorroInclusionMobile from "./../../imagenes/slider/ahorro-inclusion-mobile.jpg";
import creditoPremiumDesktop from "./../../imagenes/slider/credito-premium-desktop.jpg";
import creditoPremiumMobile from "./../../imagenes/slider/credito-premium-mobile.jpg";
import creditoecofuturoDesktop from "./../../imagenes/slider/credito-eco-futuro-desktop.jpg";
import creditoecofuturoMobile from "./../../imagenes/slider/credito-eco-futuro-mobile.jpg";
import educacionFinancieraDesktop from "./../../imagenes/slider/educacion-financiera-desktop.jpg";
import educacionFinancieraMobile from "./../../imagenes/slider/educacion-financiera-mobile.jpg";
import medicinaGeneralDesktop from "./../../imagenes/slider/medicinageneral-desktopl.jpg";
import medicinaGeneralMobile from "./../../imagenes/slider/medicinageneral-mobile.jpg";
import creditoenlineaDesktop from "./../../imagenes/slider/creditoenlinea-desktop.jpg";
import creditoenlineaMobile from "./../../imagenes/slider/creditoenlinea-mobile.jpg";
import invierteutilidadesDesktop from "./../../imagenes/slider/invierte-utilidades-desktop.jpg";
import invierteutilidadesMobile from "./../../imagenes/slider/invierte-utilidades-mobile.png";
import facilitoDesktop from "./../../imagenes/slider/facilito-desktop.png";
import facilitoDesktop2 from "./../../imagenes/slider/facilito-desktop-2.png";
import facilitoMobile from "./../../imagenes/slider/facilito-mobile.png";
import acumulaPuntoDesktop from "./../../imagenes/slider/acumulaPuntos-Desktop.png";
import acumulaPuntoMobile from "./../../imagenes/acumula-puntos.jpg";
import { Link } from "react-router-dom";

class Slider extends React.Component {
  render() {
    const slides = [
      {
        link: "https://pagos.facilito.com.ec/aplicacion/futurolamanense",
        imgDesktop: facilitoDesktop,
        imgMobile: facilitoMobile,
        target: true,
      },
      {
        link: "/deposito-plazo-fijo",
        imgDesktop: invierteutilidadesDesktop,
        imgMobile: invierteutilidadesMobile,
        target: false,
      },
      {
        link: "/futuro-premia",
        imgDesktop: acumulaPuntoDesktop,
        imgMobile: acumulaPuntoMobile,
        target: false,
      },
      {
        link: "https://solicitudcredito.futurolamanense.fin.ec/",
        imgDesktop: creditoenlineaDesktop,
        imgMobile: creditoenlineaMobile,
        target: true,
      },
      {
        link: "/medicina-general",
        imgDesktop: medicinaGeneralDesktop,
        imgMobile: medicinaGeneralMobile,
        target: false,
      },
      {
        link: "https://pagos.facilito.com.ec/aplicacion/futurolamanense",
        imgDesktop: facilitoDesktop2,
        imgMobile: facilitoMobile,
        target: true,
      },
      {
        link: "/eco-futuro",
        imgDesktop: creditoecofuturoDesktop,
        imgMobile: creditoecofuturoMobile,
        target: false,
      },
      {
        link: "/educacion-financiera",
        imgDesktop: educacionFinancieraDesktop,
        imgMobile: educacionFinancieraMobile,
        target: false,
      },
      {
        link: "/credito-premium",
        imgDesktop: creditoPremiumDesktop,
        imgMobile: creditoPremiumMobile,
        target: false,
      },
      {
        link: "/credito-futuconfianza",
        imgDesktop: creditoFutuconfianzaDesktop,
        imgMobile: creditoFutuconfianzaMobile,
        target: false,
      },
      {
        link: "/ahorro-inclusion",
        imgDesktop: ahorroInclusionDesktop,
        imgMobile: ahorroInclusionMobile,
        target: false,
      },
      {
        link: "/saras",
        imgDesktop: saraDesktop,
        imgMobile: saraMobile,
        target: false,
      },
    ];

    return (
      <section id="sliderc" className="p-0">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          navigation={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          slidesPerView={1}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              {slide.target ? (
                <a href={slide.link} target="_blank">
                  <img
                    src={slide.imgDesktop}
                    className="w-100 image-slider-pc"
                  />
                  <img
                    src={slide.imgMobile}
                    className="w-100 image-slider-mobile"
                  />
                </a>
              ) : (
                <Link to={slide.link}>
                  <img
                    src={slide.imgDesktop}
                    className="w-100 image-slider-pc"
                    alt={`Slide ${index} - Desktop`}
                  />
                  <img
                    src={slide.imgMobile}
                    className="w-100 image-slider-mobile"
                    alt={`Slide ${index} - Mobile`}
                  />
                </Link>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    );
  }
}

export default Slider;
