import React from "react";
import PremiosImg from "../../imagenes/acumula-puntos.jpg";
import apiVisitas from "../../services/visitasApi";
import apiPremios from "../../services/ganaPuntosApi";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../styles/GanaPuntos.css";

class FuturoTePremia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPointClient: null,
      listAwards: [],
      loading: false,
      error: false,
      form: {
        buscadortxt: "",
        year: new Date().getFullYear(),
      },
      selectedAward: null,
      existeCliente: "vacio",
    };
  }

  cards = [
    {
      to: "/cuenta-ahorros",
      image: "assets/img/ahorro-de-dinero.png",
      title: "Ahorro con Incremento de Saldos",
      description:
        "Haz crecer tu cuenta de ahorros y gana puntos por cada incremento en tu saldo mensual. Mientras más ahorres, más puntos acumularás para canjear en increíbles premios.",
    },
    {
      to: "/deposito-plazo-fijo",
      image: "assets/img/ganador.png",
      title: "Inversiones a Plazo Fijo",
      description:
        "Realiza inversiones a plazo fijo y recibe puntos según el monto y el tiempo de inversión. Es una excelente forma de hacer crecer tu dinero y obtener beneficios adicionales.",
    },
    {
      to: "/recomienda-y-gana",
      image: "assets/img/sugerir.png",
      title: "Recomienda y gana",
      description:
        "Invita a tus amigos y familiares a unirse a la cooperativa y recibe puntos por cada nuevo socio que se registre con tu código de recomendación. ¡Ayuda a otros y gana recompensas!",
    },
    {
      to: "/futuro-premia",
      image: "assets/img/salario.png",
      title: "Pagos Puntuales",
      description:
        "Ser puntual con tus pagos de crédito tiene recompensa. Gana puntos adicionales por cada cuota pagada a tiempo y accede a bonificaciones especiales al finalizar el año.",
    },
  ];

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  agregarVisitaFuturoTePremia = async () => {
    try {
      await apiVisitas.visitas.create({ tipo: "Futuro Te Premia" });
    } catch (error) {
      console.log("Error al registrar la visita: " + error);
    }
  };

  getPremios = async () => {
    this.setState({ loading: true });
    try {
      const data = await apiPremios.listAwards.create();
      this.setState({
        loading: false,
        error: false,
        listAwards: data.response,
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, error: true });
    }
  };

  componentDidMount() {
    this.getPremios();
    this.agregarVisitaFuturoTePremia();
  }

  searchPerson = async () => {
    this.setState({ loading: true });
    try {
      if (this.state.form.buscadortxt == "") {
        return;
      }

      const data = await apiPremios.getSecuencialClient.create(
        this.state.form.buscadortxt
      );
      if (data.response) {
        const pointClient = await apiPremios.listScoreTotal.create({
          secuencialCliente: data.response[0].secuencialCliente,
          year: this.state.form.year,
        });

        if (pointClient.response) {
          this.setState({
            existeCliente: "existe",
            dataPointClient: pointClient.response,
          });
        } else {
          this.setState({ existeCliente: "noexiste", dataPointClient: null });
        }
      } else {
        this.setState({ existeCliente: "noexiste", dataPointClient: null });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, error: true, existeCliente: "vacio" });
    } finally {
      this.state.form.buscadortxt = "";
    }
  };

  pointShow = () => {
    if (this.state.existeCliente == "existe") {
      return (
        <div className="box">
          <h4 className="text-success fw-bold">
            🎁 ¡Tienes {this.state.dataPointClient.puntosAcumulados} puntos para
            canjear! 🎉
          </h4>
          <h5 className="texto-justificado">
            Explora nuestro <Link to={"#catalogo"}>Catálogo de Premios</Link> y
            elige entre una variedad de opciones como viajes, electrodomésticos,
            artículos deportivos y mucho más. Acércate a cualquiera de nuestras{" "}
            <Link to={"/agencias"}>agencias</Link> y canjea tus puntos por
            experiencias y productos increíbles. ¡Tu fidelidad merece ser
            premiada! ✨
          </h5>
        </div>
      );
    } else if (this.state.existeCliente == "noexiste") {
      return (
        <div className="box">
          <h4 className="text-warning fw-bold">
            🚨 ¡Aún no eres socio de la cooperativa! 🚨
          </h4>
          <h5 className="texto-justificado">
            Únete hoy abriendo una{" "}
            <Link to={"/cuenta-ahorros"}>cuenta de ahorros</Link> en cualquiera
            de nuestras <Link to={"/agencias"}>agencias</Link> y comienza a
            acumular puntos automáticamente. ¡Cánjealos por increíbles premios
            como viajes, electrodomésticos, balones y mucho más! No dejes pasar
            esta oportunidad de ganar fabulosos premios. 🎁✨
          </h5>
        </div>
      );
    }
  };

  openModal = (award) => {
    this.setState({ selectedAward: award });
  };

  render() {
    return (
      <React.Fragment>
        <main id="main">
          <section id="hero" className="d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <h1>🎉 Futuro Te Premia 🎉</h1>
                  <h2 className="text-justify">
                    Un programa exclusivo de la Cooperativa Futuro Lamanense
                    diseñado para premiar tu fidelidad y compromiso. Acumula
                    puntos con tus ahorros, inversiones, recomendaciones y pagos
                    puntuales.
                  </h2>
                  <p className="text-white fw-bold text-justify">
                    Mientras más ahorres, más oportunidades tienes de canjear
                    tus puntos por fabulosos premios. ¡Es nuestra forma de
                    agradecerte por confiar en nosotros!
                  </p>
                  <div className="d-flex justify-content-center justify-content-lg-start">
                    <a href="#como-ganar" className="btn-get-started scrollto">
                      Descubre cómo ganar puntos
                    </a>
                  </div>
                </div>
                <div
                  className="col-lg-6 order-1 order-lg-2 hero-img text-center"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <img
                    src={PremiosImg}
                    className="img-fluid w-75 animated rounded"
                    alt="Futuro Te Premia"
                  />
                </div>
              </div>
            </div>
          </section>

          {/* Como Ganar Puntos */}
          <section id="como-ganar" className="services section-bg">
            <div className="container" data-aos="fade-up">
              <h2 className="text-center text-primario fw-bold">
                ¿Cómo Ganar Puntos?
              </h2>
              <p className="text-center">
                Ganar puntos es fácil. Solo necesitas participar activamente en
                los diferentes productos y servicios de la cooperativa.
                ¡Descubre todas las formas en las que puedes sumar puntos y
                alcanzar grandes premios!
              </p>
              <div className="row mt-4 row-gap-4">
                {this.cards.map((item, index) => (
                  <div
                    key={index}
                    className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    {/* <Link to={item.to} className="text-decoration-none"> */}
                      <div
                        id="cardComoGanar"
                        className="card p-3 text-center border-0 shadow"
                      >
                        <div className="d-flex justify-content-center my-3">
                          <img
                            src={item.image}
                            alt={"como-ganar-" + index}
                            width={100}
                            height={100}
                          />
                        </div>
                        <span className="text-primario fw-bold">{item.title}</span>
                        {/* <p>{item.description}</p> */}
                      </div>
                    {/* </Link> */}
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Consulta Puntos */}
          <section id="consulta" className="container my-5" data-aos="fade-up">
            <h2 className="text-primario fw-bold text-center">
              🔍 Consulta tus Puntos
            </h2>
            <p className="text-center">
              Accede a la app móvil de la cooperativa o acude a nuestras
              oficinas para conocer el saldo actual de tus puntos y las opciones
              disponibles para canjearlos.
            </p>
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <label className="ps-1 fw-bold">Identificación:</label>
                  <input
                    className="form-control"
                    onChange={this.handleChange}
                    name="buscadortxt"
                    value={this.state.form.buscadortxt}
                    required
                  />

                  <div className="text-end mt-2">
                    <button
                      className=" btnn"
                      onClick={() => this.searchPerson()}
                    >
                      Buscar
                    </button>
                  </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-6">{this.pointShow()}</div>
              </div>
            </div>
          </section>

          {/* Catalogo premios */}
          <section
            id="catalogo"
            className="services section-bg my-5"
            data-aos="fade-up"
          >
            <div className="container aos-init aos-animate">
              <h2 className="text-center text-primario fw-bold">
                🎁 Catálogo de Premios
              </h2>
              <p className="text-center my-4">
                ¡Tus puntos tienen valor! Canjea tus puntos en la agencia más
                cercana y disfruta de una variedad de productos y experiencias
                diseñadas para premiar tu fidelidad.
              </p>
              <div className="card-container">
                {this.state.listAwards.map((item, index) => (
                  <div key={index} className="card-content">
                    <div className="img">
                      <img
                        src={apiPremios.urlBaseImage + item.imageMain}
                        alt={`award-${index}`}
                      />
                    </div>
                    <span>{item.points} Puntos</span>

                    <button
                      className="card-button"
                      data-bs-toggle="modal"
                      data-bs-target="#awardModal"
                      onClick={() => this.openModal(item)}
                    >
                      Ver más
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Modal Bootstrap */}
          <div
            className="modal fade"
            id="awardModal"
            tabIndex="-1"
            aria-labelledby="awardModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content">
                <div className="modal-header">
                  <h2
                    className="modal-title text-center w-100 text-white"
                    id="awardModalLabel"
                  >
                    {this.state.selectedAward?.points} puntos
                  </h2>
                  <button
                    type="button"
                    className="btn-close text-white fw-bold"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  {this.state.selectedAward && (
                    <>
                      <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        navigation={true}
                        autoplay={{
                          delay: 3000,
                          disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                        slidesPerView={1}
                      >
                        {this.state.selectedAward.images.map((slide, index) => (
                          <SwiperSlide key={index} className="swiper-content">
                            {this.state.selectedAward.id == 12 && (
                              <div class="limited-label">Cupo Limitado</div>
                            )}
                            <img
                              src={apiPremios.urlBaseImage + slide}
                              alt="Banner"
                            />
                            <span className="award-name">
                              {this.state.selectedAward.awards[
                                index
                              ].toUpperCase()}
                            </span>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <div class="award-details">
                        <div class="award-description">
                          <span className="fw-bold">Nota: </span>
                          <span>{this.state.selectedAward.description}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default FuturoTePremia;
