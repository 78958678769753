import React from "react";
import OdontologiaImg from "../../imagenes/baners-paginas/medicinageneral.png";

class MedicinaGeneral extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="hero" className="d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Servicio de Medicina General</h1>
                                <h2>COAC Futuro Lamanense pone a disposición el servicio de medicina general gratuito para socios con fondo Mortuorio.</h2>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                                <img src={OdontologiaImg} className="img-fluid  rounded w-50" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="services" className="services section-bg">
                        <br /> <br />
                        <div className="container" data-aos="fade-up">
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                    <h5>
                                        Pensando en el bienestar integral de nuestros socios/as, ponemos a su disposición el servicio de Medicina General.
                                    </h5>
                                    <br />
                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Ofrecemos:
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        - Consulta médica
                                    </h5>
                                    <h5 className="ps-4">
                                        - Diagnóstico, tratamiento y seguimiento de enfermedades crónicas no trasmisibles (DIABETES MELLITUS, HIPERTENSION, ANEMIA)
                                    </h5>
                                    <h5 className="ps-4">
                                        - Control de niños, adolecentes y adulto mayor.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Nebulizaciones.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Revisión y limpieza de oídos.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Desparasitación.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Curación de heridas.
                                    </h5>
                                    <h5 className="ps-4">
                                        - Toma de signos vitales(tensión arterial, peso, talla, IMC, glucosa)
                                    </h5>
                                    <h5 className="ps-4">
                                        - Planificación familiar
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xl-12 col-md-6 align-items-stretch" data-aos="zoom-in" data-aos-delay="100">

                                    <div className="alert alert-primary" role="alert">
                                        <h5 className="texto-justificado mb-0">
                                            Agenda tu cita
                                        </h5>
                                    </div>
                                    <h5 className="ps-4">
                                        Ahora puedes agendar tu cita directamente desde nuestra aplicación móvil <a href="https://bit.ly/billeteradigital">Billetera Digital</a> , desde la <a href="https://bit.ly/billeteradigital">web</a> o de forma presencial en atención al cliente en cada una de nuestras agencias.
                                    </h5>
                                    <br />
                                </div>
                            </div>
                            <br />
                        </div>
                        <br />
                    </section>
                </main>
            </React.Fragment>
        )
    }
}

export default MedicinaGeneral;