import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../styles/Slider-swiper.css";
class CreditoInversiones extends React.Component {
  // Arreglo de créditos
  creditosInversiones = [
    {
      link: "/credito-consumo",
      title: "Crédito de consumo",
      description:
        "Un crédito destinado a cumplir tus sueños y anhelos, financiamos la compra de bienes y pago de servicios, para que hagas realidad todos tus proyectos.",
      delay: 100,
    },
    {
      link: "/credito-inmobiliario",
      title: "Crédito Inmobiliario",
      description:
        "Es hora de hacer realidad tu sueño de tener una vivienda propia. Te ofrecemos el apoyo y financiamiento que necesitas para construir el hogar que siempre has deseado.",
      delay: 200,
    },
    {
      link: "/microcredito",
      title: "Microcrédito",
      description:
        "Queremos verte crecer, por eso te ofrecemos el mejor crédito para tu negocio, accede hasta $60.000, a 72 meses plazo, con frecuencia de pago mensual o semestral.",
      delay: 300,
    },
    {
      link: "/eco-futuro",
      title: "Eco Futuro",
      description:
        "Impulsamos proyectos ecológicos financiando materias primas y equipos, promoviendo la sostenibilidad y la eficiencia energética para un futuro más responsable.",
      delay: 400,
    },
    {
      link: "/credito-futuconfianza",
      title: "Crédito Futuconfianza",
      description:
        "Este crédito apoya a nuestros socios con negocios establecidos en comercio, servicios o producción. Estamos aquí para ayudarte a alcanzar tus sueños.",
      delay: 500,
    },
    {
      link: "/credito-premium",
      title: "Crédito Premium",
      description:
        "Aprovecha nuestro crédito para impulsar tu comercio o servicio. Analizamos tu capacidad de pago para ofrecerte soluciones financieras adecuadas y accesibles.",
      delay: 600,
    },
    // Si necesitas agregar más créditos, simplemente añade un nuevo objeto aquí
    // {
    //   link: "/emprende-futuro",
    //   title: "Crédito Violeta",
    //   description:
    //     "Un crédito destinado a mujeres emprendedoras y visionarias con ganas de cumplir sus sueños y anhelos, con la tasa más baja del mercado.",
    //   delay: 400,
    // },
  ];

  render() {
    return (
      <React.Fragment>
        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>NUESTROS Créditos</h2>
            </div>
            <Swiper
              style={{ width: "90%" }}
              slidesPerView={1}
              slidesPerGroupSkip={1}
              spaceBetween={50}
              centeredSlides={false}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
              grabCursor={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Navigation, Pagination, Autoplay]}
            >
              {this.creditosInversiones.map((credito, index) => (
                <SwiperSlide key={index} className="slide">
                  <div
                    className="slide-item"
                    data-aos="zoom-in"
                    data-aos-delay={credito.delay}
                  >
                    <div className="icon-box">
                      <h4>
                        <Link to={credito.link}>{credito.title}</Link>
                      </h4>
                      <p className="texto-justificado">{credito.description}</p>
                      <Link to={credito.link}>
                        <div
                          className="mt-5 w-100 text-center p-2 rounded-2"
                          style={{ backgroundColor: "#47b2e4" }}
                        >
                          <span style={{ color: "white" }}>
                            Más información
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default CreditoInversiones;
